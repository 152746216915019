import React from 'react';
import './index.less';
import { Select } from 'antd';
const ModalComponent = Loader.loadBaseComponent('ModalComponent');
const Option = Select.Option;
const { placeTypeWithIcon, placeFeature} = Dict.map;
class PlaceLabelModal extends React.Component {
  onSelect = (parentId,value) => {
    let similarArr = []
    placeTypeWithIcon.forEach(v => {
      if (v.parentId === parentId){
        similarArr.push(v.value)
      }
    })
    this.props.itemClick(value, 'placeTags', similarArr)

  }
  render() {
    let { visible, onCancel ,onOk,itemClick,tags} = this.props;
    return (
      <ModalComponent
        className="place-label-modal"
        visible={visible}
        destroyOnClose={true}
        onOk={() => onOk && onOk()}
        onCancel={() => onCancel && onCancel()}
        width="460px"
        title="编辑场所标签"
      >
        <div className='place-label-view'>
          <div className='c-checkbox'>
            <div className='label'>
              类型：
            </div>
            <div className='label-box'>
            {
              placeTypeWithIcon.map(v => {
                if (v.parentId) {
                  if (v.isParent){
                    return <Select
                    onSelect={this.onSelect.bind(this,v.value)}
                    className = {
                      placeTypeWithIcon.find(item => item.parentId === v.value && tags.placeTags.indexOf(item.value) >= 0) && placeTypeWithIcon.find(item => item.parentId === v.value && tags.placeTags.indexOf(item.value) >= 0).value&&'active'
                    }
                    placeholder={`选择${v.label}`}
                    dropdownClassName="label-select-drop"
                    value = {
                      placeTypeWithIcon.find(item => item.parentId === v.value && tags.placeTags.indexOf(item.value) >= 0) && placeTypeWithIcon.find(item => item.parentId === v.value && tags.placeTags.indexOf(item.value) >= 0).value
                    }
                  >
                    {
                      placeTypeWithIcon.filter(item => item.parentId === v.value).map(typeItem => {
                        return <Option value={typeItem.value}>{typeItem.label}</Option>;
                      })
                    }
                  </Select>
                  }
                }else{
                  return <div className={`label-item ${tags.placeTags.indexOf(v.value)>=0 && 'active'}`} onClick={itemClick.bind(this,v.value,'placeTags')}>
                  {v.label}
                  </div>
                }
              })
            }
            </div>
          </div>
          <div className='c-checkbox'>
            <div className='label'>
              特征：
            </div>
            <div className='label-box'>
            {
              placeFeature.map(v => {
                return <div className={`label-item ${tags.featureTags.indexOf(v.value)>=0 && 'active'}`} onClick={itemClick.bind(this,v.value,'featureTags')}>
                 {v.label}
                </div>
              })
            }
            </div>
          </div>
        </div>
      </ModalComponent>
    );
  }
}

export default PlaceLabelModal;
